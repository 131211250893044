<template>
	<div :style="background" class="login">
		<my-title></my-title>
		<div class="center">
			<div class="item">
				<div @click="logout">输入工号：</div>
				<input required type="text" class="right" v-model="param.username" />
			</div>
			<div class="item">
				<div>输入密码：</div>
				<input required class="right" v-model="param.password" type="password" />
			</div>
		</div>
		<div class="bottom">
			<span class="sure" @click="openCallNumber">确认登录</span>
		</div>
		<img src="../assets/image/about.png" class="about" @click="openAbout" />
	</div>
</template>
<script>
import MyTitle from "../components/MyTitle.vue"
import api from "../api/index";
import { setToken, removeToken, setUserInfo, getUserInfo } from '../utils/auth'
export default {
	components: {
		MyTitle
	},
	data () {
		return {
			background: {
				backgroundColor: '#5A9AD1',
				// 背景图片地址
				backgroundImage: 'url(' + require('../assets/image/lineup_comm_bg_land.png') + ')',
				// 背景图片是否重复
				backgroundRepeat: 'no-repeat',
				// 背景图片大小
				backgroundSize: '100% auto',
				// 背景图片位置
				backgroundPosition: '0 0'

			},
			socket: null,
			param: {
				username: '',//工号
				password: '',//密码
				callDeviceMacAddr: ''
			},
			userInfo: {},//用户

		}
	},
	created () {
		document.onkeydown = (e) => {
			e = window.event || e
			if (this.$route.path === '/login' && e.keyCode === 13) {
				if (!this.param.username) {
					this.$toast({
						message: "工号不能为空",
						position: 'middle'
					});
					return
				}
				if (!this.param.password) {
					this.$toast({
						message: "密码能为空",
						position: 'middle'
					});
					return
				}
				this.login()  //登录函数
			}
		}
	},
	mounted () {
		let username = localStorage.getItem("username")
		if (username) {
			this.param.username = username
		}
		let macAddr = localStorage.getItem("macAddr");
		this.param.callDeviceMacAddr = macAddr
	},
	methods: {
		logout () {
			this.$router.go(-1)
		},
		//跳转到关于页面
		openAbout () {
			this.$router.push({
				name: 'AboutInfo',
			})
		},

		//登录接口
		login: async function () {
			// console.log(api.login(this.param));
			setToken(null)
			const res = await api.login(this.param);
			// let { code = 0, token = "", msg = "" } = res ?? {};
			// console.log(res)
			if (res.code == 200) {
				setToken(res.token)
				this.getUserInfo()
			} else {
				this.$toast({
					message: res.msg,
					position: 'middle'

				});
			}
		},
		//根据token获得用户信息
		getUserInfo: async function () {
			const res = await api.getUserInfo();
			this.userInfo = res?.data ?? {}
			localStorage.setItem("username", this.param.username)
			setUserInfo(this.userInfo)
			this.$router.push({
				name: 'CallNumber',
			})

		},
		openCallNumber () {
			if (!this.param.username) {
				this.$toast({
					message: "工号不能为空",
					position: 'middle'
				});
				return
			}
			if (!this.param.password) {
				this.$toast({
					message: "密码能为空",
					position: 'middle'
				});
				return
			}
			this.login()

		}
	}
}
</script>
<style lang="scss" scoped>
.about {
	position: absolute;
	height: 20px;
	width: 20px;
	bottom: 10px;
	right: 20px;
}
.login {
	height: 100%;
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	.center {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.item {
			display: flex;
			margin-bottom: 60px;
			flex-direction: row;
			justify-content: center;
			color: #fff;
			font-size: 30px;
			.right {
				margin-left: 10px;
				color: #000;
				font-size: 30px;
			}
		}
	}
	.bottom {
		flex: 0 0 200px;
		display: flex;
		font-size: 25px;
		color: #fff;
		box-sizing: border-box;

		.sure {
			background-color: #84c7ff;
			border-radius: 5px;
			text-align: center;
			width: 230px;
			height: 60px;
			line-height: 60px;
		}
	}
}
.about-input {
	color: #000;
	margin-top: 10px;
}
</style>
